import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
// import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import PartnerArea from "@containers/partner/layout-04";
import IntroArea from "@components/introarea/layout-1";
import TabsSection from "@components/orangetabs-section/layout-1";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import BoxSection from "@components/BoxSection/layout-two/layout-1";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";

const Footer = lazy(() => import("@layout/footer/layout-01"))
const DataCollectionPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 30) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo title="Data Collection Translation Services by Andovar"
        description="Data Collection Translation Services - Get more out of your data when Andovar oversees the logistics of collection and aggregation. Contact us today!"
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["page-header-section"]} />
        <QuoteForm />
        {
          isMobile && showRestComponents ? <>
            <PartnerArea data={content["datapartners-section"]} />
          </> : !isMobile && <>
            <PartnerArea data={content["datapartners-section"]} />
          </>
        }
        <IntroArea data={content["datacoll-type"]} />
        {
          showRestComponents && <>
            <BoxSection layout={8} data={content["data-collection-applications"]} />
            <TabsSection data={content["data-body-section"]} />
            <IntroAreaReverse data={content["annotations-solutions-body"]} />
            <BoxSection layout={1} data={content["data-collection-localize-body"]} />
            <CaseStudy data={caseStudiesData} />
            <ContactArea Layout={2} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query dataCollectionPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "datacollection" }
      pageType: { eq: "datacollection-page" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

DataCollectionPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default DataCollectionPage;
